<template>
  <div class="si"></div>
  <section class="section">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-6" v-for="card in cards" :key="card.title">
          <div class="card is-flex is-flex-direction-column">
            <div class="card-content is-flex-grow-1">
              <div class="content">
                <h4>{{ card.title }}</h4>
                <p>{{ card.description }}</p>
                <div v-html="card.html"></div>
              </div>
            </div>
            <footer class="card-footer">
              <div v-html="card.footer"></div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Pricing",
  data() {
    return {
      cards: [
        {
          title: "Basic",
          description: "For regular traders and investors",
          html: "<div class='columns'>" +
            "<div class='column is-6'>" +
            "<div class='has-text-weight-semibold is-size-4'>PHP499</div>" +
            "<div>MONTHLY</div>" +
            "<div class='content'>" +
            "<form class='is-justify-content-left' action=\"https://www.paypal.com/cgi-bin/webscr\" method=\"post\" target=\"_blank\">\n" +
            "            <input type=\"hidden\" name=\"cmd\" value=\"_s-xclick\">\n" +
            "            <input type=\"hidden\" name=\"hosted_button_id\" value=\"EYY8PAXW28A2Y\">\n" +
            "            <input type=\"image\" src=\"https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif\" border=\"0\" name=\"submit\" alt=\"PayPal - The safer, easier way to pay online!\">\n" +
            "            <img alt=\"\" border=\"0\" src=\"https://www.paypalobjects.com/en_US/i/scr/pixel.gif\" width=\"1\" height=\"1\">\n" +
            "          </form>" +
            "</div>" +
            "</div>" +
            "<div class='column is-6'>" +
            "<div class='has-text-weight-semibold is-size-4'>PHP4,999</div>" +
            "<div>YEARLY</div>" +
            "<div class='container'>" +
            "<form action=\"https://www.paypal.com/cgi-bin/webscr\" method=\"post\" target=\"_blank\">\n" +
            "            <input type=\"hidden\" name=\"cmd\" value=\"_s-xclick\">\n" +
            "            <input type=\"hidden\" name=\"hosted_button_id\" value=\"6682AG5Y7XTVG\">\n" +
            "            <input type=\"image\" src=\"https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif\" border=\"0\" name=\"submit\" alt=\"PayPal - The safer, easier way to pay online!\">\n" +
            "            <img alt=\"\" border=\"0\" src=\"https://www.paypalobjects.com/en_US/i/scr/pixel.gif\" width=\"1\" height=\"1\">\n" +
            "          </form>" +
            "</div>" +
            "</div>" +
            "</div>",
        },
        {
          title: "Brokers",
          description: "For brokers and financial advisors who want to use the platform for their clients" +
            " who want to trade and invest in the Philippine Stock Market and take advantage of the platform's" +
            " features.",
          html: "<div class='columns'>" +
            "<div class='column is-6'>" +
            "<div class='has-text-weight-semibold is-size-4'>PHP4,999</div>" +
            "<div>MONTHLY</div>" +
            "<div class='content'>" +
            "<form action=\"https://www.paypal.com/cgi-bin/webscr\" method=\"post\" target=\"_top\">\n" +
            "            <input type=\"hidden\" name=\"cmd\" value=\"_s-xclick\">\n" +
            "            <input type=\"hidden\" name=\"hosted_button_id\" value=\"AUM2PVWCT4LK6\">\n" +
            "            <input type=\"image\" src=\"https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif\" border=\"0\" name=\"submit\" alt=\"PayPal - The safer, easier way to pay online!\">\n" +
            "            <img alt=\"\" border=\"0\" src=\"https://www.paypalobjects.com/en_US/i/scr/pixel.gif\" width=\"1\" height=\"1\">\n" +
            "          </form>" +

            "</div>" +
            "</div>" +
            "<div class='column is-6' style='padding-right: 10%'>" +
            "<div class='has-text-weight-semibold is-size-4'>PHP49,999</div>" +
            "<div class='text-wrap'>YEARLY</div>" +
            "<div class='container'>" +
            "<form action=\"https://www.paypal.com/cgi-bin/webscr\" method=\"post\" target=\"_top\">\n" +
            "            <input type=\"hidden\" name=\"cmd\" value=\"_s-xclick\">\n" +
            "            <input type=\"hidden\" name=\"hosted_button_id\" value=\"U9XJR633G9ZQ4\">\n" +
            "            <input type=\"image\" src=\"https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif\" border=\"0\" name=\"submit\" alt=\"PayPal - The safer, easier way to pay online!\">\n" +
            "            <img alt=\"\" border=\"0\" src=\"https://www.paypalobjects.com/en_US/i/scr/pixel.gif\" width=\"1\" height=\"1\">\n" +
            "          </form>" +
            "</div>" +
            "</div>" +
            "</div>",
        },
      ]
    }
  }
}
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
}
.card-content {
  flex-grow: 1;
}
.column.is-6 {
  align-items: stretch;
  display: flex;
}
</style>
